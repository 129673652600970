@charset "utf-8";

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials

.page__content p {
    font-size: 0.8em;
}

.page__content li {
    font-size: 0.8rem;
    margin: 0.1em 0;
}

.author__avatar img {
    border-radius: 20%;
}
